/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import { Helmet } from 'react-helmet';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import AvailableNow from 'components/AvailableNow';

class AboutAuthor extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | About the Author</title>
        </Helmet>
        <DemoNavbar />
        <AvailableNow />
        <main ref="main">
          <Container className="pt-7">
            <Row>
              <Col lg="6">
                <h2>About the Author</h2>
                <p>
                  Dr. Adam C. Bandelli is the Managing Director of Bandelli &
                  Associates. Adam has 20 years of management and consulting
                  experience in the firm’s service offerings, including board
                  consultation, senior executive selection, leadership
                  development, pre-investment due diligence, organizational
                  culture, and change management. As an expert on executive
                  leadership and relational intelligence, Adam works with CEOs
                  and senior executives to strengthen their abilities to inspire
                  and influence their people, teams, and organizations. Adam has
                  worked with leaders around the world in organizations ranging
                  from small start-up firms through global Fortune 100
                  companies. He has consulted to a diverse set of industry
                  sectors including, private equity, financial services,
                  consumer products, manufacturing, medical devices, retail,
                  energy, pharmaceuticals, sports and entertainment, nonprofit,
                  and telecommunications.
                </p>

                <p>
                  Prior to founding Bandelli & Associates, Adam was a Partner at
                  Korn Ferry, where he led the Private Equity assessment
                  practice for North America. Earlier in his career, he was a
                  Partner at RHR International, where he served as one of the
                  firm’s leaders on CEO Succession and Leadership Development.
                  Adam received his Ph.D. and master’s degrees from the
                  University of South Florida in Industrial-Organizational
                  Psychology, and a bachelor’s degree concentrating in
                  Psychology and Business Management from Fairleigh Dickinson
                  University.{' '}
                </p>

                <p>
                  Acknowledged as a world-renowned thought leader on
                  transformational leadership and organizational effectiveness,
                  he is a frequent speaker at Fortune 500 companies, leadership
                  retreats, and business and professional meetings.{' '}
                </p>
                <p>
                  As a former athlete, Adam spends his recreational
                  time long distance running and weight training. He has
                  competed in several marathons including the TCS New York City
                  Marathon. He is an avid golfer and is usually spending his
                  weekends on the golf course. His family are active members in
                  their church, ChurchAlive, and volunteer in their local
                  community.{' '}
                </p>
              </Col>
              <Col lg="6" className="p-5">
                <img
                  className="rounded img-thumbnail"
                  src={require('assets/img/adam-portrait.png')}
                />
              </Col>
            </Row>
          </Container>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default AboutAuthor;
