/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import { Helmet } from 'react-helmet';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import AvailableNow from 'components/AvailableNow.js';

class AboutBook extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | About the Book</title>
        </Helmet>
        <DemoNavbar />
        <AvailableNow />
        <main ref="main">
          <Container className="pt-7">
            <Row>
              <Col>
                <h2>About the Book</h2>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <p>
                  In this insightful book, Dr. Adam C. Bandelli wrestles with
                  the age-old questions: What makes a great leader? What talents
                  and capabilities impact the way leaders work with others?
                </p>
                <p>
                  What skills do leaders need to practice to develop positive
                  long-lasting relationships with their people? How can leaders
                  leverage the right behaviors to leave a legacy with their
                  organizations?{' '}
                </p>
                <p>
                  Dr. Bandelli emphasizes the importance of vision setting and
                  how it sets the course for leaders and their organizations. He
                  discusses the power of passion and commitment to driving
                  positive organizational outcomes. He outlines the importance
                  of discipline and dedication to one’s area of expertise. He
                  covers the value of ethics and integrity on leadership. He
                  focuses on the effects of compassion and empathy on building
                  positive working relationships. He illustrates the need for
                  inspiration and innovation to motivate and engage employees.{' '}
                </p>
                <p>
                  In times of change and uncertainty, What Every Leader Needs,
                  will inspire you to grow as a leader. It will help you
                  cultivate the skills needed to develop yourself and the people
                  around you. It will be a tool you can keep coming back to
                  again and again along your leadership journey!
                </p>
              </Col>
              <Col lg="6">
                <img
                  className="media-object img-thumbnail"
                  src={require('assets/img/book-cover.png')}
                />
              </Col>
            </Row>
          </Container>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default AboutBook;
