/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import { Helmet } from 'react-helmet';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import AvailableNow from 'components/AvailableNow';

class MediaCoverage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | Media Coverage</title>
        </Helmet>
        <DemoNavbar />
        <AvailableNow />
        <main ref="main">
          <Container className="pt-7">
            <Row>
              <Col lg={{ size: 10 }} xs={{ size: 11 }}>
                <h2>Media Coverage</h2>
                <div>
                  <h4>
                    <a
                      href="https://www.hr.com/en/magazines/personal_excellence_essentials/july_2022_personal_excellence/the-relationally-intelligent-introvert_l5gdwcri.html?s=OO19ERLBCGp3QF32"
                      target="_blank"
                    >
                      The Relationally Intelligent Introvert - You don’t have to
                      be extroverted to be wired for connection
                      <br />
                      <small class="text-muted">HR.com</small>
                    </a>
                  </h4>
                  <p>
                    It’s time for introverts to be acknowledged and commended
                    for many of the great qualities they bring to developing
                    relationships.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://www.success.com/all-successful-leaders-have-this-type-of-intelligence-in-common-according-to-psychologist-adam-bandelli/?utm_content=buffer6d084&utm_medium=social&utm_source=linkedin.com&utm_campaign=buffer"
                      target="_blank"
                    >
                      What Successful Leaders Have in Common
                      <br />
                      <small class="text-muted">SUCCESS Magazine</small>
                    </a>
                  </h4>
                  <p>
                    Through inspiring leaders to prioritize individuals’
                    well-being over productivity, Bandelli is helping
                    entrepreneurs create a more compassionate and profitable
                    workplace.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://podcasts.apple.com/us/podcast/254-dr-adam-c-bandelli-relational-intelligence-the/id1021817294?i=1000567825448"
                      target="_blank"
                    >
                      Dr. Adam C. Bandelli, Relational Intelligence: The Key to
                      Transform Relationships
                      <br />
                      <small class="text-muted">
                        The Strategy Skills Podcast: Management Consulting |
                        Strategy, Operations & Implementation | Critical
                        Thinking
                      </small>
                    </a>
                  </h4>
                  <p>
                    In this episode, Adam speaks about how reliance on
                    technology has kept people technologically connected but has
                    prevented them from building genuine and sustainable
                    relationships.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://podcasts.apple.com/us/podcast/become-a-better-leader-with-dr-adam-bandelli/id1562886870?i=1000566836254"
                      target="_blank"
                    >
                      Using Relational Intelligence to Become a Better Leader
                      with Dr. Adam Bandelli
                      <br />
                      <small class="text-muted">
                        Brilliant Thoughts with Tristan Ahumada
                      </small>
                    </a>
                  </h4>
                  <p>
                    Business psychologist and leadership management consultant
                    Dr. Adam Bandelli joins Tristan to discuss the theme of his
                    new book, Relational Intelligence: The Five Essential Skills
                    You Need to Build Life-Changing Relationships. Learn how to
                    use your influence to intentionally create positive
                    connections and bring out the best in others.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://chiefexecutive.net/bridging-the-generational-gap-using-relational-intelligence-to-unite-your-workforce/"
                      target="_blank"
                    >
                      Bridging the Generational Gap: Using Relational
                      Intelligence to Unite Your Workforce
                      <br />
                      <small class="text-muted">Chief Executive</small>
                    </a>
                  </h4>
                  <p>
                    The five essential skills will help you attract, retain and
                    engage talent spanning across the different
                    generations—because we all want, more or less, the same
                    things.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://futureoffieldservice.com/2022/06/08/the-power-of-relational-intelligence/"
                      target="_blank"
                    >
                      Become a Better Leader with Relational Intelligence and
                      Dr. Adam Bandelli
                      <br />
                      <small class="text-muted">Tristana Humada Podcast</small>
                    </a>
                  </h4>
                  <p>
                    Today’s guest is Adam Bandelli, Visionary Founder & Managing
                    Director of Bandelli & Associates. With a Ph.D. and master’s
                    degree in Organizational Psychology and 20 years of
                    management and leadership coaching experience under his
                    belt, he recently finished his book, Relational
                    Intelligence: The Five Essential Skills You Need to Build
                    Life-Changing Relationships, which will be released soon.
                  </p>
                  <hr />
                </div>{' '}
                <div>
                  <h4>
                    <a
                      href="https://futureoffieldservice.com/2022/06/08/the-power-of-relational-intelligence/"
                      target="_blank"
                    >
                      The Power of Relational Intelligence
                      <br />
                      <small class="text-muted">
                        The Future of Field Service Podcast
                      </small>
                    </a>
                  </h4>
                  <p>
                    Sarah welcomes back Founder & Managing Director, Bandelli &
                    Associates and author, Dr. Adam Bandelli, to discuss his new
                    book Relational Intelligence: The Five Essential Skills You
                    Need to Build Life-Changing Relationships.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.hr.com/en/magazines/all_articles/6-keys-to-develop-life-changing-relationships-with_l2ztun56.html"
                      target="_blank"
                    >
                      6 Keys To Develop Life-Changing Relationships With Your
                      Mentees <br />
                      <small class="text-muted">HR.com</small>
                    </a>
                  </h4>
                  <p>
                    Mentors can be valuable resources to employees in providing
                    them with guidance on career management and navigating
                    organizational politics.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://ceoworld.biz/2022/05/07/six-behaviors-senior-executives-can-practice-to-develop-cultures-of-empathy-in-their-organizations-the-relational-intelligence-skill-of-understanding-others/"
                      target="_blank"
                    >
                      Six Behaviors Senior Executives Can Practice to Develop
                      Cultures of Empathy in their Organizations: The Relational
                      Intelligence Skill of Understanding Others
                      <br />
                      <small class="text-muted">CEO World Magazine</small>
                    </a>
                  </h4>
                  <p>
                    The number one key to developing a culture of empathy is the
                    relational intelligence skill of understanding others.
                    Understanding others is the ability to be intentional about
                    putting in the time and effort needed to get to know your
                    employees on a deep level.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.fastcompany.com/90748575/5-ways-to-see-if-a-job-candidate-is-empathetic"
                      target="_blank"
                    >
                      5 ways to see if a job candidate is empathetic
                      <br />
                      <small class="text-muted">Fast Company</small>
                    </a>
                  </h4>
                  <p>
                    Looking to add someone with emotional intelligence to your
                    team? Here’s how to check for empathy in a job interview.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://startupnation.com/books/5-ways-to-retain-talent-by-using-relational-intelligence-bandelli/"
                      target="_blank"
                    >
                      5 Ways to Retain Talent by Using Relational Intelligence
                      <br />
                      <small class="text-muted">StartupNation</small>
                    </a>
                  </h4>
                  <p>
                    Leaders will develop dynamic, life-changing relationships
                    with their people when they practice the five skills of
                    relational intelligence. To provide the emotional support
                    needed to retain your workers, be intentional about the
                    relationships you develop with them.{' '}
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://builtin.com/people-management/4-keys-relational-intelligence"
                      target="_blank"
                    >
                      The 4 Qualities Leaders Need to Build Trust and Boost Team
                      Effectiveness
                      <br />
                      <small class="text-muted">Built In</small>
                    </a>
                  </h4>
                  <p>
                    Relational intelligence is the key ingredient to trusting,
                    high-performing professional relationships.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.upnextpodcast.com/episode-212/"
                      target="_blank"
                    >
                      Dr. Adam Bandelli and Relational Intelligence on the
                      UpNext Podcast
                      <br />
                      <small class="text-muted">UpNext Podcast</small>
                    </a>
                  </h4>
                  <p>
                    In today’s podcast Adam Bandelli discusses his new book,
                    Relational Intelligence, and some of the practical steps
                    that listeners can take to improve their ability to build
                    relationships with coworkers and clients.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://itbusinessnet.com/2022/04/relational-intelligence-on-the-frontlines-how-to-develop-great-partnerships-with-your-customers/"
                      target="_blank"
                    >
                      Relational Intelligence on the Frontlines: How to Develop
                      Great Partnerships with Your Customers
                      <br />
                      <small class="text-muted">IT Business Net</small>
                    </a>
                  </h4>
                  <p>
                    Relational intelligence is the ability to successfully
                    connect with people and build strong, long-lasting
                    relationships.{' '}
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://bizdig.co/career/eq-is-not-enough-why-you-need-to-learn-relational-intelligence/"
                      target="_blank"
                    >
                      EQ is Not Enough: Why You Need to Learn Relational
                      Intelligence
                      <br />
                      <small class="text-muted">BizDig</small>
                    </a>
                  </h4>
                  <p>
                    Leaders who consistently practice relational intelligence
                    have employees who are more engaged in their work, are more
                    satisfied with their jobs, and are more loyal and committed
                    to their organizations.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.youtube.com/watch?v=GB1-RXlE5ec"
                      target="_blank"
                    >
                      Relational Intelligence &amp; Relationship Building in the
                      Workplace
                      <br />
                      <small class="text-muted">HCI Webinar</small>
                    </a>
                  </h4>
                  <p>
                    In this HCI Webinar, Dr. Jonathan H. Westover talks with
                    Adam C. Bandelli about relational intelligence and the
                    importance of relationship-building in the workplace.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://pcladuke.medium.com/adam-c-bandelli-ph-d-20625a0b2c1e"
                      target="_blank"
                    >
                      On The Labor Shortage &amp; The 5 Things We Must Do To
                      Attract &amp; Retain Great Talent: A Conversation with Dr.
                      Adam C. Bandelli
                      <br />
                      <small class="text-muted">
                        An Interview with Phil La Duke
                      </small>
                    </a>
                  </h4>
                  <p>
                    The pandemic has allowed people to reevaluate what they want
                    from work. This “Great Reevaluation” has led to the “Great
                    Resignation” which has left the US with a great big labor
                    shortage and a supply chain crisis. What can we do to
                    reverse this trend?
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.ttec.com/resources/cxpod/drive-employee-retention-relational-intelligence"
                      target="_blank"
                    >
                      Drive Employee Retention with Relational Intelligence
                      <br />
                      <small class="text-muted">The CX Pod</small>
                    </a>
                  </h4>
                  <p>
                    In the era of the Great Resignation, businesses across
                    industries are struggling to retain employees.
                    Organizational psychologist Dr. Adam Bandelli explains how
                    relational intelligence shapes employee and employer
                    relationships and what leaders can do to strengthen those
                    connections to improve employee engagement and retention.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://ceoworld.biz/2022/04/12/watch-out-for-interpersonal-blind-spots-what-relationally-intelligent-ceos-and-their-employees-need-and-what-they-must-avoid/"
                      target="_blank"
                    >
                      Watch Out for Interpersonal Blind Spots: What Relationally
                      Intelligent CEOs and their Employees Need and What They
                      Must Avoid
                      <br />
                      <small class="text-muted">CEO World</small>
                    </a>
                  </h4>
                  <p>
                    The best way for both senior executives and their people to
                    commit to making relationships work is by learning and
                    practicing the five essential skills of relational
                    intelligence.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://futureoffieldservice.com/2022/04/04/relational-intelligence-on-the-frontlines-how-to-develop-great-partnerships-with-your-customers/"
                      target="_blank"
                    >
                      Relational Intelligence on the Frontlines: How to Develop
                      Great Partnerships with Your Customers
                      <br />
                      <small class="text-muted">Future of Field Service</small>
                    </a>
                  </h4>
                  <p>
                    Relational intelligence is the ability to successfully
                    connect with people and build strong, long-lasting
                    relationships.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://hrdailyadvisor.blr.com/2022/03/31/building-relational-intelligence-why-establishing-raport-with-your-employees-is-crucial/"
                      target="_blank"
                    >
                      Building Relational Intelligence: Why Establishing Rapport
                      with Your Employees is Crucial
                      <br />
                      <small class="text-muted">HR Daily Advisor</small>
                    </a>
                  </h4>
                  <p>
                    Great rapport builders have empathy for people. They know
                    how to relate to others’ needs and can see things through
                    their eyes and walk in their shoes. Leaders who know how to
                    build rapport are also curious and inquisitive.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://player.fm/series/3253740/323507334"
                      target="_blank"
                    >
                      How Emotional Support Can Retain Workers
                      <br />
                      <small class="text-muted">
                        TribePod - A Proactive Talent Podcast
                      </small>
                    </a>
                  </h4>
                  <p>
                    In his new book, RELATIONAL INTELLIGENCE: The Five Essential
                    Skills You Need to Build Life-Changing Relationships (April
                    2022), Dr. Bandelli draws on extensive research to introduce
                    the concept of “Relational Intelligence” and explains why it
                    is at the heart of effective leadership. Tune in for a VERY
                    informative interview with an industry leader.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://podcasts.apple.com/ca/podcast/relational-intelligence-with-dr-adam-bandelli/id1530978841?i=1000555414314"
                      target="_blank"
                    >
                      Relational Intelligence with Dr. Adam Bandelli
                      <br />
                      <small class="text-muted">
                        The Leadership Launchpad Project
                      </small>
                    </a>
                  </h4>
                  <p>
                    On this week's The Leadership Launchpad Project, Adam
                    Bandelli joins the show to talk about Relational
                    Intelligence. We talk about what it is, why it's important
                    and Adam shares some tips on building it.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://chiefexecutive.net/retaining-your-top-talent-the-business-case-for-relational-intelligence/"
                      target="_blank"
                    >
                      Retaining Top Talent: The Business Case for Relational
                      Intelligence
                      <br />
                      <small class="text-muted">Chief Executive</small>
                    </a>
                  </h4>
                  <p>
                    Relationally intelligent leaders practice these five
                    essential skills that lead to greater levels of employee
                    engagement, job satisfaction and employee retention.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://chiefexecutive.net/the-mindset-and-methods-of-the-best-servant-leaders/"
                      target="_blank"
                    >
                      The Mindset and Methods of Servant Leaders
                      <br />
                      <small class="text-muted">Chief Executive</small>
                    </a>
                  </h4>
                  <p>
                    Relationally intelligent leaders practice these five
                    essential skills that create greater levels of employee
                    engagement, financial performance and job satisfaction.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://budtoboss.com/teambuilding/putting-people-and-culture-first-the-importance-of-developing-relational-intelligence-on-the-frontlines/"
                      target="_blank"
                    >
                      Putting People and Culture First: Importance of Relational
                      Intelligence on the Frontlines
                      <br />
                      <small class="text-muted">Bud to Boss</small>
                    </a>
                  </h4>
                  <p>
                    Making the transition from individual contributor to a new
                    manager or supervisor can be an exciting and challenging
                    time. There are several things that a frontline leader needs
                    to think about.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://digitizingpolaris.com/workplace-relationships-can-be-game-changers-for-leaders-e1874ec79a0c"
                      target="_blank"
                    >
                      Workplace relationships can be game-changers for leaders
                      <br />
                      <small class="text-muted">Digitizing Polaris</small>
                    </a>
                  </h4>
                  <p>
                    Bandelli has observed the devastating impact of both the
                    pandemic and increased reliance on technology on people’s
                    ability to create the kinds of relationships needed not only
                    for business and career success, but also for personal
                    satisfaction and growth.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://builtin.com/career-development/interpersonal-skills-guide"
                      target="_blank"
                    >
                      Relational Intelligence: The Most Important Interpersonal
                      Skill Everyone Should Develop
                      <br />
                      <small class="text-muted">Built In</small>
                    </a>
                  </h4>
                  <p>
                    Sometimes called “soft skills” or “people skills,” these
                    tools are key to creating and maintaining a successful
                    career.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://chiefexecutive.net/servant-leadership-5-relational-intelligence-skills-you-need-to-have/"
                      target="_blank"
                    >
                      Servant Leadership: 5 Relational Intelligence Skills You
                      Need to Have
                      <br />
                      <small class="text-muted">Chief Executive</small>
                    </a>
                  </h4>
                  <p>
                    Our research at Bandelli &amp; Associates has found that
                    relationally intelligent leaders practice five essential
                    skills that create greater levels of employee engagement,
                    financial performance, and job satisfaction.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.adammendler.com/blog/adam-bandelli"
                      target="_blank"
                    >
                      Develop Relational Intelligence: Interview with Author Dr.
                      Adam Bandelli
                      <br />
                      <small class="text-muted">Adam Mendler</small>
                    </a>
                  </h4>
                  <p>
                    Adam Mendler is the CEO of The Veloz Group, where he
                    co-founded and oversees ventures across a wide variety of
                    industries. Adam is also the creator and host of the
                    business and leadership podcast Thirty Minute Mentors, where
                    he goes one on one with America's most successful people.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://futureoffieldservice.com/2021/12/15/leadership-competencies-in-a-time-of-unprecedented-change/"
                      target="_blank"
                    >
                      Leadership Competencies in a Time of Unprecedented Change
                      <br />
                      <small class="text-muted">
                        Future of Field Service Podcast
                      </small>
                    </a>
                  </h4>
                  <p>
                    Sarah welcomes Dr. Adam Bandelli, author of the book What
                    Every Leader Needs: The Ten Universal and Indisputable
                    Competencies of Leadership Effectiveness, to discuss how the
                    unprecedented circumstances of the last two years have
                    changed what leaders need to do to be effective.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://hrcommedia-public.s3.amazonaws.com/ExcellenceEssentials/LE/2022/JANUARY/LE_JAN_2022/page_25.html"
                      target="_blank"
                    >
                      What is Relational Intelligence?
                      <small class="text-muted">HR.com</small>
                    </a>
                  </h4>
                  <p>Why does relational intelligence matter now?</p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://hrcommedia-public.s3.amazonaws.com/ExcellenceEssentials/LE/2021/December/page_35.html"
                      target="_blank"
                    >
                      Why Innovative Leaders Thrive{' '}
                      <small class="text-muted">HR.com</small>
                    </a>
                  </h4>
                  <p>The power of creative thinking.</p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://inbusinessphx.com/leadership-management/the-best-leaders-know-how-to-set-a-vision#.YdTAzy1h3XR"
                      target="_blank"
                    >
                      The Best Leaders Know How to Set a Vision
                      <small class="text-muted">In Business</small>
                    </a>
                  </h4>
                  <p>
                    An often-overlooked step in motivating and inspiring their
                    people
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://businesspress.vegas/columns/experts-corner/inspirational-leadership-3-keys-to-motivating-empowering-people-29751/"
                      target="_blank"
                    >
                      Inspirational leadership: 3 keys to motivating, empowering
                      people
                      <br />
                      <small class="text-muted">Las Vegas Business Press</small>
                    </a>
                  </h4>
                  <p>
                    There are three keys to inspirational leadership. These
                    factors build cultures of excellence when implemented on a
                    regular basis.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.youtube.com/watch?v=1kCRxsWay6E"
                      target="_blank"
                    >
                      What Every Leaders Needs with Dr. Adam Bandelli
                      <br />
                      <small class="text-muted">
                        The Remarkable Leadership Podcast
                      </small>
                    </a>
                  </h4>
                  <p>
                    Join us for an engaging conversation where we talk about the
                    universal competencies that all leaders need to be
                    successful.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://businesspress.vegas/columns/experts-corner/the-power-of-endurance-emerging-from-the-covid-19-pandemic-29384/"
                      target="_blank"
                    >
                      The power of Endurance: Emerging from the COVID-19
                      pandemic
                      <br />
                      <small class="text-muted">LV Business Press</small>
                    </a>
                  </h4>
                  <p>
                    There are four keys to endurance. Practicing these behaviors
                    helps leaders navigate through a crisis and uncharted
                    territories.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://technologyadvice.com/blog/human-resources/characteristics-every-leader-needs/"
                      target="_blank"
                    >
                      Building a Strong Executive Team: Characteristics Every
                      Leader Needs
                      <br />
                      <small class="text-muted">Technology Advice</small>
                    </a>
                  </h4>
                  <p>
                    Joseph Stalin and Abraham Lincoln look like vastly different
                    leaders on the surface, but if you look closer, you’ll see
                    some similar characteristics.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a href="https://lnkd.in/ea84NCaZ" target="_blank">
                      How to Lead in Times of Transition with Dr. Adam Bandelli{' '}
                      <br />
                      <small class="text-muted">
                        Savvy Business, Life Unscripted
                      </small>
                    </a>
                  </h4>
                  <p>
                    Dr. Adam C. Bandelli, author of What Every Leader Needs: The
                    Ten Universal and Indisputable Competencies of Leadership
                    Effectiveness, speaks on how to lead in times of transition.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.youngupstarts.com/2021/10/07/consistency-matters-building-sustainable-leadership-excellence/"
                      target="_blank"
                    >
                      Consistency Matters: Building Sustainable Leadership
                      Excellence
                      <small class="text-muted">Young Upstarts</small>
                    </a>
                  </h4>
                  <p>
                    There are three things that every leader must do to show up
                    consistently. Practice these behaviors and you will have
                    great influence and impact on your organization.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://hrdailyadvisor.blr.com/2021/10/06/4-ways-to-motivate-and-lead-teams-with-passion/"
                      target="_blank"
                    >
                      4 Ways to Motivate and Lead Teams with Passion
                      <small class="text-muted">HR Daily Advisor</small>
                    </a>
                  </h4>
                  <p>
                    Passionate leaders garner the greatest support and
                    followership from their people. They trigger commitment and
                    dedication to key strategic objectives from employees at all
                    levels of their organizations.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a href="https://web.hr.com/4gov7" target="_blank">
                      Who are your culture carriers?
                      <small class="text-muted">HR.com</small>
                    </a>
                  </h4>
                  <p>
                    Developing cultures of excellence in a post-pandemic world.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://ceoworld.biz/2021/10/01/how-the-best-leaders-drive-results-the-power-of-vigilant-leadership/"
                      target="_blank"
                    >
                      How the Best Leaders Drive Results: The Power of Vigilant
                      Leadership
                      <small class="text-muted">CEOWORLD Magazine</small>
                    </a>
                  </h4>
                  <p>
                    In a world of ever-evolving change and uncertainty, leaders
                    need many different skills to drive performance and deliver
                    exceptional results for their organizations. The best
                    leaders know how to motivate and inspire others.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://medium.com/authority-magazine/dr-adam-c-bandelli-of-bandelli-associates-5-things-you-need-to-be-a-highly-effective-leader-dcb62493253e"
                      target="_blank"
                    >
                      Dr. Adam C. Bandelli of Bandelli &amp; Associates: 5
                      Things You Need to be a Highly Effective Leader During
                      Turbulent Times
                      <small class="text-muted">Authority Magazine</small>
                    </a>
                  </h4>
                  <p>
                    As part of our series about the “Five Things You Need to Be
                    a Highly Effective Leader During Turbulent Times,” we had
                    the pleasure of interviewing Dr. Adam Bandelli.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://ceoworld.biz/2021/09/20/four-keys-leaders-need-to-build-cultures-of-commitment/"
                      target="_blank"
                    >
                      Four Keys Leaders Need to Build Cultures of Commitment
                      <small class="text-muted">CEOWORLD Magazine</small>
                    </a>
                  </h4>
                  <p>
                    As senior executives and business leaders, commitment is
                    about remaining steadfast and unwavering in your goals and
                    objectives.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.fastcompany.com/90670345/how-to-help-a-remote-coworker-through-a-tough-time"
                      target="_blank"
                    >
                      How to help a remote coworker through a tough time
                      <small class="text-muted">Fast Company</small>
                    </a>
                  </h4>
                  <p>
                    When you’re in the same office, it’s easier to spot
                    employees going through a rough patch. Here’s how to help
                    when you’re in a remote or hybrid setting.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://chiefexecutive.net/creating-a-legacy-that-matters/"
                      target="_blank"
                    >
                      Creating A Legacy That Matters
                      <small class="text-muted">Chief Executive</small>
                    </a>
                  </h4>
                  <p>
                    Your lasting value will be measured by succession, and how
                    well your people do after your gone. Some guidelines for
                    being both strategic and intentional.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.youngupstarts.com/2021/09/02/youre-never-too-young-to-lead/"
                      target="_blank"
                    >
                      You’re Never Too Young To Lead
                      <small class="text-muted">Young Upstarts</small>
                    </a>
                  </h4>
                  <p>
                    As a business psychologist and management consultant, the
                    most memorable kids were “line leaders.” The eager ones who
                    ran up to the front of the line prior to the procession to
                    lunch, library, or recess. These were the kids that most
                    likely went on to assume roles of leadership in whatever
                    profession they chose.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://www.aarp.org/work/working-at-50-plus/info-2021/asking-coworkers-vaccine-status.html"
                      target="_blank"
                    >
                      Is It OK to Ask Your Coworkers if They're Vaccinated?
                      <small class="text-muted">AARP</small>
                    </a>
                  </h4>
                  <p>
                    As you face new expectations about how you should interact
                    with coworkers while also considering your own safety
                    concerns, here are some tips to navigate this tricky
                    situation.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://ceoworld.biz/2021/08/24/leadership-lessons-knowing-when-to-listen-versus-when-to-speak/"
                      target="_blank"
                    >
                      Leadership Lessons: Knowing When to Listen Versus When to
                      Speak
                      <small class="text-muted">CEOWORLD Magazine</small>
                    </a>
                  </h4>
                  <p>
                    So, what causes leaders to speak up when they shouldn’t? Why
                    do most people need to listen more than they speak? Here are
                    some guidelines for balancing the ongoing battle between
                    speaking and listening.
                  </p>
                  <hr />
                </div>
                <div>
                  <h4>
                    <a
                      href="https://podcasts.apple.com/us/podcast/the-leadership-launchpad-project/id1530978841?i=1000516732703"
                      target="_blank"
                    >
                      The Leadership Launchpad Project
                      <small class="text-muted">
                        By Rob Kalwarowsky &amp; Susan Hobson
                      </small>
                    </a>
                  </h4>
                  <p>
                    On this episode, Susan Hobson &amp; Rob Kalwarowsky welcome
                    Adam Bandelli to the show. We discuss servant leadership,
                    why legacy matters and finding meaning.
                  </p>
                  <hr />
                </div>
              </Col>
            </Row>
          </Container>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default MediaCoverage;
