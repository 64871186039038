/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';

class DemoNavbar extends React.Component {
  componentDidMount() {
    // let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    // headroom.init();
  }
  state = {
    collapseClasses: '',
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: 'collapsing-out',
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: '',
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main"
            expand={true}
            id="navbar-main"
          >
            <Container className="flex-column flex-md-row">
                <Nav
                  className="text-center navbar-nav-hover pl-2 align-items-lg-center"
                  navbar
                >
                  <NavItem>
                    <NavLink
                      href="/"
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/about/book"
                    >
                      The Book
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/about/author"
                    >
                      The Author
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/about/firm"
                    >
                      The Firm
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/about/testimonials"
                    >
                      Testimonials
                    </NavLink>
                  </NavItem>                  <NavItem>
                    <NavLink
                      href="/media-coverage"
                    >
                      Media Coverage
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center d-sm-flex flex-ml-lg-auto" navbar>
                  <NavItem className="d-sm-block">
                    <Button
                      className="btn-icon border-0"
                      color="secondary"
                      outline
                      href="https://www.facebook.com/officialbandelliassociates"
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-facebook" />
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="d-sm-block">
                    <Button
                      className="btn-icon border-0"
                      color="secondary"
                      outline
                      href="https://www.instagram.com/official_bandelliassociates/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-instagram" />
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="d-sm-block">
                    <Button
                      className="btn-icon border-0"
                      color="secondary"
                      outline
                      href="https://www.twitter.com/abandelli/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-twitter" />
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="d-sm-block">
                    <Button
                      className="btn-icon border-0"
                      color="secondary"
                      outline
                      href="https://www.youtube.com/channel/UCDhhamF26lfFqe8UAsEFv4g"
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-youtube-play" />
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="d-sm-block">
                    <Button
                      className="btn-icon border-0"
                      color="secondary"
                      outline
                      href="https://www.linkedin.com/in/adambandelli"
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-linkedin" />
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
