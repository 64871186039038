/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "./App.css";

import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import AboutAuthor from "views/AboutAuthor";
import AboutBook from "views/AboutBook";
import AboutFirm from "views/AboutFirm";
import AboutTestimonials from "views/AboutTestimonials";
import MediaCoverage from "views/MediaCoverage";

ReactDOM.render(
  <BrowserRouter>
    <Helmet defaultTitle="What Every Leader Needs | By Dr. Adam C. Bandelli">
      <meta charSet="utf-8" />
      <link rel="canonical" href="http://www.whateveryleaderneeds.com/" />
      <description>
        The Ten Universal And Indisputable Competencies of Leadership
        Effectiveness
      </description>
    </Helmet>
    <Switch>
      <Route path="/" exact render={(props) => <Landing {...props} />} />
      <Route
        path="/landing-page"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route
        path="/about/author"
        exact
        render={(props) => <AboutAuthor {...props} />}
      />
      <Route
        path="/about/book"
        exact
        render={(props) => <AboutBook {...props} />}
      />
      <Route
        path="/about/firm"
        exact
        render={(props) => <AboutFirm {...props} />}
      />{" "}
      <Route
        path="/about/testimonials"
        exact
        render={(props) => <AboutTestimonials {...props} />}
      />
      <Route
        path="/media-coverage"
        exact
        render={(props) => <MediaCoverage {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
