import React from 'react';
import { Alert, Button } from 'reactstrap'

const AvailableNow = () => (
    <Alert color="info" className="text-center m-0 rounded-0">
    <div className="h5 text-white ">
      <strong>Available now</strong> at the following retailers!
    </div>
    <Button className="m-1" target="_blank" href="https://www.amazon.com/dp/1636301363/ref=cm_sw_r_sms_api_glt_fabc_77B0QXK3MY7V14SWAV8R">
      <i className="fa fa-amazon mr-1" />
      Amazon
    </Button>
    <Button className="m-1" target="_blank" href="https://m.barnesandnoble.com/w/what-every-leader-needs-adam-c-bandelli-phd/1139210588?ean=9781636301365">
      <i className="fa fa-book mr-1" />
      Barnes & Noble
    </Button>
    <Button className="m-1" target="_blank" href="https://books.apple.com/us/book/what-every-leader-needs/id1567438699">
      <i className="fa fa-apple mr-1" />
      Apple Books
    </Button>
    <Button className="m-1" target="_blank" href="https://www.audible.com/pd/B095JLJ23X/?source_code=AUDFPWS0223189MWT-BK-ACX0-259188&ref=acx_bty_BK_ACX0_259188_rh_us">
      <i className="fa fa-volume-up mr-1" />
      Audible
    </Button>
  </Alert>

);

export default AvailableNow;