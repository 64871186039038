/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import { Helmet } from 'react-helmet';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import CardsFooter from 'components/Footers/CardsFooter.js';

import AvailableNow from 'components/AvailableNow';

class AboutFirm extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | About the Firm</title>
        </Helmet>
        <DemoNavbar />
        <AvailableNow />
        <main ref="main">
          <Container className="pt-7">
            <Row>
              <Col lg={{ size: 12 }}>
                <img
                  width="55%"
                  className="mx-auto mb-6 d-block"
                  src={require('assets/img/BALogo_Red-1.png')}
                  title="Bandelli & Associates logo"
                  alt="Bandelli & Associates logo"
                />
              </Col>
              <Col lg={{ size: 6 }}>
                <h2>About The Firm </h2>
                <p>
                  At Bandelli & Associates, we believe the growth and
                  development of people is the highest calling of leadership.
                  Our consultants leverage psychological insights, business
                  acumen, and years of firsthand experience to help our clients
                  identify, unlock, and unleash their true leadership potential.
                  We work side-by-side with leaders to provide them with the
                  knowledge and wisdom needed to have a lasting positive impact
                  on their people, teams, and organizations.
                </p>
                <p>
                  We believe in the power of relational intelligence. This is
                  demonstrated in the authentic and genuine relationships we
                  build with all of our clients. We challenge the leaders we
                  work with to embrace diversity of thought. We empower leaders
                  to capitalize on their greatest talents, skills, and core
                  capabilities. We inspire our clients to be bold, take
                  calculated risks, and drive sustainable change.
                </p>
                <p>
                  We are fully committed to our clients’ dreams, goals, and
                  ambitions. Whether it is assessment and executive integration,
                  coaching and leadership development, succession planning,
                  senior team effectiveness, or driving transformational change,
                  our team is ready to serve as trusted advisors along your
                  leadership journey. Contact us today to learn more, and take
                  your leadership to the next level.{' '}
                  <a href="http://www.bandelliandassociates.com">
                    www.bandelliandassociates.com
                  </a>
                </p>
                <p>Leadership Matters. Without It, People Fail.</p>
              </Col>
              <Col lg={{ size: 6 }}>
                <img
                  src={require('assets/img/Firm Page.jpg')}
                  className="img-thumbnail img-fluid rounded"
                  alt="Associates walking"
                />
              </Col>
            </Row>
          </Container>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default AboutFirm;
