/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Helmet } from 'react-helmet';

// reactstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import AvailableNow from 'components/AvailableNow';

// index page sections
import Download from '../IndexSections/Download.js';

const playlist = [
  { name: 'Chapter 1 - Vision', src: 'audio/01-Vision.mp3' },
  { name: 'Chapter 2 - Passion', src: 'audio/02-Passion.mp3' },
  { name: 'Chapter 3 - Compassion', src: 'audio/03-Compassion.mp3' },
  { name: 'Chapter 4 - Inspiration', src: 'audio/04-Inspiration.mp3' },
  { name: 'Chapter 5 - Wisdom', src: 'audio/05-Wisdom.mp3' },
];

class Landing extends React.Component {
  state = {
    currentMusicIndex: 0,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  handleClickPrevious = () => {
    this.setState((prevState) => ({
      currentMusicIndex:
        prevState.currentMusicIndex === 0
          ? playlist.length - 1
          : prevState.currentMusicIndex - 1,
    }));
  };

  handleClickNext = () => {
    this.setState((prevState) => ({
      currentMusicIndex:
        prevState.currentMusicIndex < playlist.length - 1
          ? prevState.currentMusicIndex + 1
          : 0,
    }));
  };
  handleTrackClick = (index) => {
    this.setState(() => ({
      currentMusicIndex: index,
    }));
  };
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | By Dr. Adam C. Bandelli</title>
          <description>The Ten Universal And Indisputable Competencies of Leadership Effectiveness</description>
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <AvailableNow />
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg={{ size: 6, offset: -1 }}>
                      <img
                        width="100%"
                        className="media-object"
                        src={require('assets/img/hero-book-render.png')}
                      />
                    </Col>
                    <Col lg={{ size: 6 }} className="text-white">
                      <p className="display-4">What Type of Leader Are You?</p>
                      <p>
                        Are you the visionary out in front setting the course
                        for others to follow? Do you have the discipline and
                        determination to make your dreams become a reality? Do
                        you inspire commitment and followership from your
                        people? Do you have the passion and burning desire to
                        leave a lasting legacy?
                      </p>
                      <p>
                        In this insightful and inspiring book, Dr. Adam Bandelli
                        outlines the ten universal and indisputable competencies
                        of leadership effectiveness. Based on two decades of
                        research and experience on leadership excellence, he
                        outlines how you can put these skills into practice
                        today. What Every Leader Needs is a guide that will help
                        you maximize your leadership impact and influence with
                        your people, teams, and organizations.
                      </p>
                      <br />
                      <strong className="h4 text-white">
                        By Dr. Adam C. Bandelli
                      </strong>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-sm">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-uppercase">The Book</h6>
                          <p className="description mt-3">
                            Take your leadership to the next level. Aspire for
                            greatness and strive for all that you can be!
                          </p>
                          <Button
                            className="mt-4"
                            color="info"
                            href="/about/book"
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-uppercase">The Author</h6>
                          <p className="description mt-3">
                            Meet Dr. Adam C. Bandelli, a business psychologist
                            and leadership advisory management consultant.
                          </p>
                          <Button
                            className="mt-4"
                            color="info"
                            href="/about/author"
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <h6 className="text-uppercase">The Firm</h6>
                          <p className="description mt-3">
                            Bandelli & Associates is a boutique firm focusing on
                            leadership development and organizational
                            effectiveness.
                          </p>
                          <Button
                            className="mt-4"
                            color="info"
                            href="/about/firm"
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <hr width="50%"/>
          <section className="section section-sm">
            <Container>
              <Row className="justify-content-md-center">
                <Col
                  lg="10"
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <h2 className="display-3 text-center">
                    A Look Inside The Book
                  </h2>
                  <div className="embed-responsive embed-responsive-16by9 shadow-lg rounded">
                    <ReactPlayer
                      className="embed-responsive-item"
                      width="100%"
                      height="100%"
                      url="http://bandelliandassociates.com/static/WELN.mp4"
                      controls
                      pip={false}
                      light={require('assets/img/adam-book-thumbnail.png')}
                      playsinline
                      config={{ file: { forceVideo: true } }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <hr width="50%"/>
          <section className="section section-sm">
            <Container>
              <Row className="row-grid align-items-center">
                <Col lg={{ size: 8, offset: 2 }}>
                  <h3 className="display-3">Strengthen Your Leadership</h3>
                  <p>
                    What separates the great leaders from the average ones? How
                    do leaders thrive and have the best impact on their people?
                    In What Every Leader Needs, Dr. Adam C. Bandelli outlines
                    the ten leadership skills that are critical to your success.
                    Using personal stories and case studies from twenty years of
                    experience as an organizational psychologist and leadership
                    advisory management consultant, Dr. Bandelli explains why
                    each competency is a must have in your leadership
                    repertoire. No matter where you are in your leadership
                    journey, learning and practicing these skills will
                    dramatically affect the way that you lead. Beyond discussing
                    the ten key leadership competencies, this book will
                    challenge you to put the skills into action. Each chapter
                    lays out a blueprint for how you can improve as a leader.
                  </p>
                  <p>
                    From Fortune 100 CEOs down to small business owners, this
                    book is for the leader in you. It will help you to develop
                    and polish the essential leadership competencies necessary
                    to get you to the top and keep you there. No matter what
                    your unique leadership style is, What Every Leader Needs,
                    will serve as a guide that you can keep coming back to as
                    you develop and grow. Investing in who you are as a leader
                    will dramatically impact your effectiveness and influence
                    with others. Leadership matters now more than ever before.
                    The leaders of today will help shape society tomorrow. This
                    book will help you get there – it will bring you greater
                    confidence, satisfaction, and fulfillment in how you lead.{' '}
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <hr width="50%"/>
          <section className="section section-sm">
            <Container>
              <Row className="row-grid align-items-center">
                <Col lg={{ size: 8, offset: 2 }}>
                  <h3 className="display-3">Give It A Listen</h3>
                  <p>Preview the first 5 chapters of the audio book</p>
                  <div class="card border-dark" style={{ width: '18rem;' }}>
                    <div class="card-header bg-dark">
                      <AudioPlayer
                        autoPlayAfterSrcChange={true}
                        showSkipControls={true}
                        showJumpControls={false}
                        src={playlist[this.state.currentMusicIndex].src}
                        onClickPrevious={this.handleClickPrevious}
                        onClickNext={this.handleClickNext}
                      />
                    </div>
                    <ul class="list-group list-group-flush">
                      {playlist.map((v, i) => (
                        <li
                          style={{ fontSize: '1.25rem', cursor: 'pointer' }}
                          className={
                            v === playlist[this.state.currentMusicIndex]
                              ? 'list-group-item font-weight-bold'
                              : 'list-group-item'
                          }
                          onClick={() => this.handleTrackClick(i)}
                        >{`${v.name}`}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
