/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import { Helmet } from 'react-helmet';

// core components
import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import CardsFooter from 'components/Footers/CardsFooter.js';
import AvailableNow from 'components/AvailableNow';

class AboutBook extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>What Every Leader Needs | Testimonials</title>
        </Helmet>
        <DemoNavbar />
        <AvailableNow />
        <main ref="main">
          <Container className="pt-7">
            <Row>
              <Col lg={{ size: 10 }} xs={{ size: 11 }}>
                <h2>Testimonials</h2>
                <blockquote className="blockquote my-5 pr-5">
                  <p className="">
                    “We don’t always expect a look inside the sphere of
                    corporate management to evoke such humane behaviors, but
                    Adam Bandelli’s “What Every Leader Needs” harvests ten
                    teachable attributes of leadership, that provide a framework
                    for his case studies from the workplace. Steeped in
                    self-awareness based leadership development, drawing on real
                    world examples from Bandelli’s two decades of coaching, the
                    narrative is as empathetic as it is instructive. It’s a
                    workbook to highlight and return to, for any dedicated
                    student of leadership.”
                  </p>
                  <footer className="blockquote-footer">
                    Dr. Randall P. White
                    <cite title="Source Title" className="d-block">
                      Chair of Leadership, eMBA HEC Paris and Doha <br/> Co-author
                      of the book "Breaking the Glass Ceiling"
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10, offset: 2 }} xs={{ size: 11, offset: 1}}>
                <blockquote className="blockquote my-5 text-right pl-2">
                  <p className="">
                    “I have known Adam for years, and I can confidently say that
                    ‘What Every Leader Needs’ is a master course on leadership
                    from a man who lives out the ideals in this book. It’s
                    difficult to find fresh insights on the topic of leadership,
                    but Adam delivers them in every chapter. If you are looking
                    to grow as a leader, look no further!”{' '}
                  </p>
                  <footer className="blockquote-footer text-right">
                    Pastor Dave Murphy{' '}
                    <cite title="Source Title" className="d-block">
                      Founding and Lead Pastor of Vital Church <br/> Author of
                      “Undefeated: Conquering Your Doubts and Living in God’s
                      Fullness.”
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10 }} xs={{ size: 11 }}>
                <blockquote className="blockquote my-5 pr-2">
                  <p className="">
                    “What Every Leader Needs is a user’s manual that provides an
                    advanced course in leadership. It provides a wealth of
                    specific advice that is evidence-based from a leadership
                    expert who has worked with leaders at all levels. Not only
                    does the book provide a list of the 10 main competencies
                    that successful leaders have, it provides a step-by-step
                    roadmap for how to develop each one.”{' '}
                  </p>
                  <footer className="blockquote-footer">
                    Dr. Paul Spector-Professor Emeritus
                    <cite title="Source Title" className="d-block">
                      Muma College of Business, University of South Florida
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10, offset: 2 }} xs={{ size: 11, offset: 1}}>
                <blockquote className="blockquote my-5 text-right pl-5">
                  <p className="">
                    “Dr. Bandelli’s fluid writing style makes the reader feel
                    that they have personal access to a well-regarded leadership
                    coach. He effectively synthesizes elements of theory,
                    research, and practice to ground leadership concepts. The
                    utilization of “action-steps” enables the reader to
                    personalize the competencies and serves as a blueprint for
                    one’s own leadership development.”{' '}
                  </p>
                  <footer className="blockquote-footer">
                    Dr. Robert M. Chell, Professor Emeritus of Psychology
                    <cite title="Source Title" className="d-block">
                      Fairleigh Dickinson University
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10 }} xs={{ size: 11 }} >
                <blockquote className="blockquote my-5 pr-5">
                  <p className="">
                    “I can say from my 25 years of teaching experience at
                    different universities, and as an Imam and leader in the
                    Muslim Community in Washington Metropolitan area, that the
                    message in What Every Leader Needs can change and improve
                    your leadership. Moreover, the ten valuable leadership
                    skills discussed can make a huge difference in managing your
                    team and leading your organization. It is a must read for
                    every leader.”
                  </p>
                  <footer className="blockquote-footer">
                    Dr. Mohamed E. Hassan
                    <cite title="Source Title" className="d-block">
                      President & CEO, Prince William Islamic Center
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10, offset: 2}} >
                <blockquote className="blockquote my-5 text-right pl-5">
                  <p className="">
                    “Dr. Bandelli has masterfully captured a lifetime of
                    leadership principles and practices in a format that both
                    captivates and educates. As an executive coach and
                    management consultant, his knowledge and ability to share
                    real-world examples of how diverse leaders think and grow,
                    will help any individual become the best version of
                    themselves. This book is a must have for your professional
                    library!”{' '}
                  </p>
                  <footer className="blockquote-footer">
                    Phillip Chacra
                    <cite title="Source Title" className="d-block">
                      Senior Vice President, Best Buy
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10 }} xs={{ size: 11 }} >
                <blockquote className="blockquote my-5 pr-5">
                  <p className="">
                    “Adam’s gift is storytelling. Each leader example paints a
                    picture for the reader and gives a real-life story on how
                    the competencies are lived, experienced, or learned. He
                    supports the learning of anyone who picks up this book by
                    describing models, research, programs, and a step-by-step
                    approach on how to develop as a leader. His years of
                    experience, his wealth of knowledge, and his appreciation of
                    mentors he has met along the way, let us get to know the
                    author as well as his clients. To be able to have so many
                    examples of leadership in action makes it a pleasure to
                    read.”
                  </p>
                  <footer className="blockquote-footer">
                    Lyne Desormeaux
                    <cite title="Source Title" className="d-block">
                      Consulting Psychologist, Master Coach <br/> CEO of
                      Desormeaux Leadership Consulting, LLC
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10, offset: 2}}>
                <blockquote className="blockquote my-5 text-right pl-5">
                  <p className="">
                    “In the world of sports, leadership is so critical to the
                    success of any organization. Dr. Bandelli’s perspective on
                    leadership is both informative and inspiring to any
                    professional sports team. He takes leadership concepts and
                    makes them easy to apply in a variety of settings. This book
                    is something our team will come back to again and again.”{' '}
                  </p>
                  <footer className="blockquote-footer">
                    Stephen Venditti
                    <cite title="Source Title" className="d-block">
                      Director of Video, New York Giants
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10 }} xs={{ size: 11 }}>
                <blockquote className="blockquote my-5 pr-5">
                  <p className="">
                    “Adam captures the essence of Leadership in his book, What
                    Every Leader Needs: The Ten Universal and Indisputable
                    Competencies of Leadership Effectiveness. It is an easy read
                    with a wealth of information and perspective from his real
                    life experiences both personally and professionally. The
                    best thing about the book is that it outlines leadership
                    effectiveness that will not only help you excel in business,
                    but provides a road map for success in all aspects of your
                    life. There's a saying in golf; the most important shot is
                    the next one! Focus on that next shot, read Adam's book and
                    set yourself up for your best round in business and in
                    life.”
                  </p>
                  <footer className="blockquote-footer">
                    Ed Walls
                    <cite title="Source Title" className="d-block">
                      PGA, Renaissance Country Club, Head Golf Professional
                    </cite>
                  </footer>
                </blockquote>
              </Col>
              <Col lg={{ size: 10, offset: 2 }} xs={{ size: 11, offset: 1}}>
                <blockquote className="blockquote my-5 pl-5 text-right">
                  <p className="">
                    “Adam Bandelli not only provides a classic, crystal clear
                    blueprint for aspiring leaders, but he also coaches us,
                    chapter by chapter, to take command of ourselves. Practical
                    and timely, Dr. Bandelli has written a book that is sorely
                    needed for our leaderless times.”
                  </p>
                  <footer className="blockquote-footer">
                    Dr. Justin DeSenso
                    <cite title="Source Title" className="d-block">
                      Assistant Professor of English and African American
                      Studies, Penn State Berks
                    </cite>
                  </footer>
                </blockquote>
              </Col>
            </Row>
          </Container>
        </main>
        <CardsFooter/>
      </>
    );
  }
}

export default AboutBook;
